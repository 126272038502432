<template>
  <b-card no-body class="card-company-table">
    <div class="mx-2 my-2">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex flex-column">
          <h3>Franchise Students</h3>
          <h4>
            {{ studentsList.length }} student{{
              studentsList.length != 1 ? "s" : ""
            }}
          </h4>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            aria-controls="StudentsListTableId"
            v-model="pagination.currentPage"
            :total-rows="studentsList.length"
            :per-page="pagination.perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <b-table
      id="StudentsListTableId"
      ref="refStudentsListTable"
      class="position-relative"
      :items="studentsList"
      responsive
      :fields="tableColumns"
      primary-key="student_user_id"
      show-empty
      empty-text="No matching records found"
      :per-page="pagination.perPage"
      :current-page="pagination.currentPage"
      selectable
    >
      <template #cell(user_name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="FILESURL + data.item.user_profile_image"
            />
          </template>
          <p>
            {{ data.item.user_name }}
          </p>
        </b-media>
      </template>
      <template #cell(user_mobile)="data">
        <div style="width: max-content">
          {{ data.value }}
        </div>
      </template>

      <template #cell(conversion_date)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">
            {{ conversion_date_fn(data.item.conversion_date) }}
          </span>
        </div>
      </template>

      <template #cell(assigned_staff)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">
            {{ data.item.assigned_staff_name }}
          </span>
        </div>
      </template>

      <template #cell(source)="data">
        <b-media vertical-align="center">
          {{
            data.item.creator_user_id
              ? data.item.creator_firstname + " " + data.item.creator_lastname
              : "website"
          }}
        </b-media>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BImg,
  BMedia,
  BRow,
  BCol,
  BPagination,
} from "bootstrap-vue";
import { FILESURL } from "@/config";
import CommonServices from "@/apiServices/CommonServices";
import moment from "moment";

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BMedia,
    BRow,
    BCol,
    BPagination,
  },
  data() {
    return {
      FILESURL,
      fields: [
        { key: "user_id", label: "Id", sortable: false },
        { key: "user_name", label: "Name", sortable: false },
        { key: "user_mobile", label: "Phone", sortable: false },
        {
          key: "pending_docs_count",
          label: "Pending Docs",
          sortable: false,
        },
      ],
      studentsList: [],
      tableColumns: [
        { key: "user_id", label: "Id", sortable: false },
        { key: "user_name", label: "Name", sortable: false },
        { key: "user_mobile", label: "Phone", sortable: false },
        { key: "conversion_date", label: "Conversion", sortable: false },
      ],
      pagination: {
        currentPage: 1,
        perPage: 5,
        perPageOptions: [5, 10, 20, 30],
      },
    };
  },
  props: ["agent_user_id", "show_all_level_data", "lead"],
  beforeMount() {
    this.getStudentsList();
  },
  watch: {
    agent_user_id(val) {
      this.getStudentsList();
    },
    show_all_level_data(val) {
      this.getStudentsList();
    },
  },
  methods: {
    conversion_date_fn(date) {
      if (date && moment(date).isValid()) {
        return moment(date).format("DD-MM-YYYY");
      } else {
        return "No";
      }
    },
    async getStudentsList() {
      try {
        const res = await CommonServices.getAssignedStudents({
          // agent_user_id: this.agent_user_id,
          lead: this.lead(),
          showAllLevelData: this.show_all_level_data
            ? this.show_all_level_data
            : null,
        });

        this.studentsList = res.data.data;
      } catch (error) {
        console.error(`Error in getStudentsList `, error);
      }
    },
    // row_clicked(e) {
    //   this.$router.push({
    //     name: "Student Details",
    //     params: { student_user_id: e.user_id },
    //     query: { to: "applications" },
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
