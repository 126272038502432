<template>
  <!-- v-if="xAxisData[0] && series[0]" -->
  <b-card v-if="xAxisData.length !== 0 && series.length !== 0">
    <div class="d-flex justify-content-between flex-wrap mb-2">
      <h3>Weekly Students</h3>
      <div class="d-flex" style="width: 308px">
        <flat-pickr
          v-model="dateRange"
          class="form-control"
          placeholder="Select Date"
          :config="{
            dateFormat: 'Y-m-d',
            altInput: true,
            altFormat: 'd-M-Y',
            allowInput: true,
            mode: 'range',
          }"
          @input="echartDateRange()"
        />
      </div>
    </div>

    <app-echart-line :option-data="option" />
  </b-card>
</template>

<script>
import { BCard, BBadge } from "bootstrap-vue";
import AppEchartLine from "@core/components/charts/echart/AppEchartLine.vue";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";

export default {
  components: {
    BCard,
    AppEchartLine,
    BBadge,
    flatPickr,
  },
  data() {
    return {
      dateRange: "",
    };
  },
  computed: {
    option: function () {
      let option = {
        xAxisData: this.xAxisData,
        series: this.series,
      };
      return option;
    },
  },
  props: {
    xAxisData: {
      type: Array,
      default: () => [],
    },
    series: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    echartDateRange() {
      let [startDate, endDate] = this.dateRange.split(" to ");
      this.$emit("echartDateRange", {
        startDate,
        endDate,
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

</style>
